
import * as client from "react-dom/client";
import React from "react";
import App from "./App.js";

import "./index.css";

console.log("run");

function main() {
  const target = document.getElementById("root");

  if (target == null) {
    console.error("could not get react root target element.");
    return;
  }

  const root = client.createRoot(target);
  root.render(<App />);
}

window.addEventListener("load", (ev) => {
  console.log('loaded');
  main();
})

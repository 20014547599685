
import React, {
  ReactNode,
  createRef,
  RefObject,
  cloneElement,
  ReactElement,
  useState,
  CSSProperties,
  useEffect, useCallback
} from "react";
import { styled } from "styled-components";
import classNames from "classnames";
import useWindowSize from "../hooks/useWindowSize.js";

/*
A tray is a method for rendering a list of items.

Trays display list items in a horizontal flexbox. As the available layout
space decreases, the least important items will be pushed into an expandable
menu. This preserves quick access when possible, without disrupting
the layout.

Tray items will always be rendered at their initial width.

Tray items are prioritised by their order in the tree.

 */
type TrayProps = {
  align?: "end" | "start" | "center";
  direction?: "up" | "down";
  children?: ReactElement | ReactElement[];
  className?: string;
  style?: CSSProperties;
}



export function Tray(props: TrayProps) {
  const { align = "start", direction = 'up', style, className } = props;
  let children = Array.isArray(props.children) ? props.children : props.children != undefined ? [props.children] : [];
  const layout = useWindowSize();
  const [count, setCount] = useState<number>(0);
  const [showMenu, setShowMenu] = useState<boolean>(false);


  const getWidth = (label: string, cb: (w: number) => any): React.RefCallback<HTMLElement> => {
    return useCallback((node) => {
      if (node !== null) {
        cb(node.offsetWidth);
        console.log(`${label}: ${node.offsetWidth}`);
      }
    }, [layout]);
  }

  const getChildWidths = (label: string, cb: (w: number[]) => any): React.RefCallback<HTMLElement> => {
    return useCallback((node) => {
      if (node !== null) {
        let w: number[] = [];
        for (let child of Array.from(node.children)) {
          w.push((child as HTMLElement).offsetWidth);
        }
        cb(w);
        console.log(`${label}: ${w.join(',')}`);
      }
    }, [layout]);
  }



  const [trayLength, setTrayLength] = useState(0);
  const trayRef = getWidth('tray', setTrayLength);

  const [toggleLength, setToggleLength] = useState(0);
  const toggleRef = getWidth('toggle', setToggleLength);

  // Create refs for each child, to get length information
  const [childLength, setChildLength] = useState(Array(count).map(() => 0));

  const innerRef = getChildWidths('inner', (n) => setChildLength(count < children.length ? n.slice(0, n.length - 1) : n));

  const [pocketLength, setPocketLength] = useState(Array(Math.max(children.length - count, 0)).map(() => 0));
  const pocketRef = getChildWidths('pocket', setPocketLength);

  //const childRefs: RefObject<HTMLElement>[] = Array(children.length).map(() => createRef());

  useEffect(() => {
    let rem = trayLength;
    console.log(childLength);
    console.log(pocketLength);
    console.log(rem);
    let n = 0;

    rem -= toggleLength;
    while (rem - childLength[n] > 0) {
      console.log(rem);
      rem -= childLength[n];
      n += 1;
    }
    let i = 0;
    while (rem - pocketLength[i] > 0) {
      console.log(rem);
      rem -= pocketLength[i];
      n += 1;
      i += 1;
    }

    console.log(rem);
    setCount(n);
    console.log(`tray show ${n}`);
  }, [trayLength, toggleLength, childLength]);

  const toggle = <button className='more' ref={toggleRef} onClick={() => setShowMenu(!showMenu)}>...</button>;

  return <TrayBox align={align} ref={trayRef} direction={direction} className={className} style={style}>
    <div className="inner" >
      <button className='expand' onClick={() => setShowMenu(!showMenu)} />
      <div className="innerItems" ref={innerRef}>
        { align == "start" ? toggle: null }
        {children.slice(0, count).map((child, i) => cloneElement(child, { key: i }) )}
        { align != "start" ? toggle: null }
      </div>
      <div className={classNames("menu", showMenu ? "show" : false)} ref={pocketRef}>
        {children.slice(count).map((child, i) => cloneElement(child, { key: i }) )}
      </div>
    </div>
  </TrayBox>
}


type TrayBoxProps = {
  align?: "end" | "start" | "center";
  direction?: "up" | "down";
}

const TrayBox = styled.div<TrayBoxProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${ p => p.align || "start" };
  width: 100%;
  height: 100%;
  
  .inner {
    position: relative;
    display: flex;
    flex-direction: ${ p => p.direction == "up" ? "column" : "column-reverse" };
    align-items: ${ p => p.align || "start" };
  }

  .more {
    
  }
  
  .expand {
    display: none;
    width: 100%;
    height: 0.75em;
    padding: 0;
    /*border: none;
    background-color: rgba(0,0,0,0.1);*/
  }
  
  .innerItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${ p => p.align || "start" };
    width: auto;
    height: 100%;
  }
  
  .menu {
    visibility: hidden;
    display: flex;
    position: absolute;
    left: ${ p => p.align == "start" ? 0 : p.align == "center" ? "50%" : "auto" };
    margin-left: ${ p => p.align == "center" ? "-50%" : "0" };
    right: ${ p => p.align == "end" ? 0 : "auto" };
    bottom: ${ p => p.direction == "up" ? "100%" : "auto" };
    top: ${ p => p.direction == "down" ? "100%" : "auto" };
    flex-direction: column;
    align-items: ${ p => p.align || "start" };
    min-width: 100%;
    padding: 0.5em;
    background-color: #fff;
  }
  
  .menu.show {
    visibility: inherit;
  }
`;



export default Tray;


import { styled } from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import proj4 from "proj4";
import {transformExtent, transform} from "ol/proj.js";
import * as olProj4 from "ol/proj/proj4.js";
import OlMap from 'ol/Map.js';
import TileLayer from 'ol/layer/Tile.js';
import View from 'ol/View.js';
import XYZ from 'ol/source/XYZ.js';
import TileGrid from "ol/tilegrid/TileGrid.js";
import Tray from "../Library/Tray.js";
import {Layer} from "ol/layer.js";

type LayerDefinitionCommon = {
  name: string,
  show: boolean,
  attribution: string[]
}

type TileXyz = LayerDefinitionCommon & {
  type: LayerType.TileXyz,
  url: string,
  projection?: string
  tileGrid?: any,
  extent?: any,
  zoom?: [number, number],
}

type LayerDefinition = TileXyz;

enum LayerType {
  TileXyz = "xyz",
  WebMappingService = "wms",
  WebFeatureService = "wfs",
}

function noNull<X>(x: X): x is Exclude<Exclude<X, null>, undefined> {
  return x != null
}

const tileGrid = new TileGrid({
  resolutions: [ 896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75 ],
  origin: [ -238375.0, 1376256.0 ]
});

const projections = [
  ["EPSG:3857", "Web Mercator - WGS84"],
  ["EPSG:27700", "British National Grid - OSGB36"],
];

const defaultViews: {[key: string]: any} = {
  "EPSG:3857": {
    projection: "EPSG:3857",
    center: [-350000, 7425000],
    zoom: 7,
  },
  "EPSG:27700": {
    projection: 'EPSG:27700',
    extent: [ -238375.0, 0.0, 900000.0, 1376256.0 ],
    resolutions: tileGrid.getResolutions(),
    minZoom: 0,
    maxZoom: 9,
    center: [ 337297, 503695 ],
    zoom: 0
  },
}


const crsMap: {[key: string]: [number, number]} = {
  "EPSG:3857::EPSG:27700": [ 693400, -6914000 ],
  "EPSG:27700::EPSG:3857": [ -693400, 6914000 ],
};

function transformCrs(p: [number, number], srcProj: string, destProj: string) {
  return transform(p, srcProj, destProj);
}


const zoomMap: {[key: string]: number} = {
  "EPSG:3857::EPSG:27700": -7,
  "EPSG:27700::EPSG:3857": 7,
};

function transformZoom(zoom: number, srcProj: string, destProj: string) {
  let k = `${srcProj}::${destProj}`;
  if (zoomMap.hasOwnProperty(k)) {
    return zoom + zoomMap[k];
  }
  return defaultViews[destProj].zoom;
}


proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs");
olProj4.register(proj4);

function initLayer(l: LayerDefinition): Layer|null {
  console.log('extent', l.extent);
  switch(l.type) {
    case LayerType.TileXyz:
      return new TileLayer({
        extent: l.extent,
        minZoom: l.zoom?.[0],
        maxZoom: l.zoom?.[1],
        source: new XYZ({
          url: l.url,
          projection: l.projection,
          tileGrid: l.tileGrid
        })
      });
  }
  return null;
}

export function Map() {

  const [projection, setProjection] = useState<string>('EPSG:27700');
  const [basemap, setBasemap] = useState<{[key: string]: string|undefined}>({
    "EPSG:3857": "OS Maps - Roads",
    "EPSG:27700": "OS Maps - Roads",
  });
  const setBaseMapByProjection = (projection: string, key: string|undefined) => {
    let n = Object.assign({}, basemap);
    n[projection] = key;
    setBasemap(n);
    console.log('change basemap', n);
  }
  console.log(' basemap', basemap);

  const [layers, setLayers] = useState<LayerDefinition[]>([
    {
      name: "Open Street Maps",
      type: LayerType.TileXyz,
      show: true,
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      projection: 'EPSG:3857',
      attribution: [
        '&#169; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors.'
      ]
    },
    {
      name: "OS Maps - Roads",
      type: LayerType.TileXyz,
      show: false,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Road_27700/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:27700',
      tileGrid: tileGrid,
      extent: [ -238375.0, 0.0, 900000.0, 1376256.0 ],
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
    {
      name: "OS Maps - Roads",
      type: LayerType.TileXyz,
      show: false,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:3857',
      extent: transformExtent([ -10.76418, 49.528423, 1.9134116, 61.331151 ], 'EPSG:4326', 'EPSG:3857'),
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
    {
      name: "OS Maps - Outdoor",
      type: LayerType.TileXyz,
      show: false,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Outdoor_27700/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:27700',
      extent: [ -238375.0, 0.0, 900000.0, 1376256.0 ],
      tileGrid: tileGrid,
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
    {
      name: "OS Maps - Outdoor",
      type: LayerType.TileXyz,
      show: false,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:3857',
      extent: transformExtent([ -10.76418, 49.528423, 1.9134116, 61.331151 ], 'EPSG:4326', 'EPSG:3857'),
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
    {
      name: "OS Maps - Light",
      type: LayerType.TileXyz,
      show: true,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Light_27700/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:27700',
      tileGrid: tileGrid,
      extent: [ -238375.0, 0.0, 900000.0, 1376256.0 ],
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
    {
      name: "OS Maps - Light",
      type: LayerType.TileXyz,
      show: false,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:3857',
      extent: transformExtent([ -10.76418, 49.528423, 1.9134116, 61.331151 ], 'EPSG:4326', 'EPSG:3857'),
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
    {
      name: "OS Maps - Leisure",
      type: LayerType.TileXyz,
      show: false,
      url: 'https://api.os.uk/maps/raster/v1/zxy/Leisure_27700/{z}/{x}/{y}.png?key=Pu97WXtxBNkqQnyusH1knbYFvELqju00',
      projection: 'EPSG:27700',
      tileGrid: tileGrid,
      extent: [ -238375.0, 0.0, 900000.0, 1376256.0 ],
      zoom: [0, 5],
      attribution: [
        'Contains OS data © Crown Copyright and database right 2020.'
      ]
    },
  ]);


  const setLayerToggle = (name: string, value: boolean) => {
    let n = layers.slice(0);
    for (let k of n) {
      if (k.name === name) {
        k.show = value;
      }
    }
    console.log("toggle layer", n);
    setLayers(n);
  }


  let map = useRef<OlMap | undefined>();
  useEffect(() => {
    if (map.current == null) {
      console.log("init map", map)
      map.current = new OlMap({
        target: 'map',
        layers: [],
        view: new View(defaultViews[projection]),
        controls: []
      });

      map.current?.on('moveend', (ev: any) => {
        console.log(ev);
        let view = map.current?.getView();
        console.log('center', view?.getCenter());
        console.log('zoom', view?.getZoom());
      })
    }


    console.log("update layers");

    let nextLayers: any[] = [];
    if (basemap[projection] != null) {
      for (let lk of layers) {
        if (lk.name === basemap[projection] && lk.projection == projection) {
          console.log(lk);
          nextLayers.push(initLayer(lk))
        }
      }
    }
    map.current?.setLayers(nextLayers);

  }, [layers, projection, basemap]);

  useEffect(() => {
    if (map.current == null) return;
    let ov = map.current?.getView();
    map.current.setView(
      new View(
        Object.assign(
          {},
          defaultViews[projection],
         {
           center: transformCrs(ov?.getCenter()! as [number, number], ov?.getProjection()?.getCode(), projection),
           zoom: transformZoom(ov?.getZoom()!, ov?.getProjection()?.getCode(), projection)
         }
        )
      )
    );
  }, [projection]);


  return <VerticalBox>
    <Canvas id="map" />
    <StatusBox>
      <Tray align="end">
        <select value={basemap[projection]} onChange={(ev) => setBaseMapByProjection(projection, ev.currentTarget.value) }>
          <option value={undefined}>None</option>
          { layers.filter(l => l.projection == projection).map((l, k) => <option key={k} value={l.name}>{ l.name }</option>)}
        </select>
        <select value={projection} onChange={(ev) => setProjection(ev.currentTarget.value)}>
          { projections.map((p, k) => <option key={k} value={p[0]}>{p[1]}</option> ) }
        </select>
      </Tray>
    </StatusBox>
  </VerticalBox>;
}

const VerticalBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  main {
    width: 100%;
    height: 100%;
  }
`;

const HorizontalBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`;

const Canvas = styled.div`
  width: 100%;
  height: 100%;
`;

const Sidebar = styled.div`
  flex: 450px 0 0;
  display: block;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
  background-color: rgba(0,0,0,0.1);
`;

const StatusBox = styled.div`
  width: 100%;
  flex: 2em 0 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  background-color: rgba(0,0,0,0.2);
`;


const StatusItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
`;

const JournalPicker = styled.select`
  padding: 0.2em 1em;
`;


export default Map;


import React from "react";
import { styled } from "styled-components";
import Map from "./Map/Map.js"


export function App() {
  return <AppBox>
    <AppBar>
    </AppBar>
    <Map />
  </AppBox>;
}



const AppBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const AppBar = styled.div`
  display: flex;
`;

const Button = styled.button`
  padding: 0.5em 1em;
`;



export default App;
